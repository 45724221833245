<template>
  <a-card title="需要我审批的" :bodyStyle="{height: '410px'}">
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="taskName" slot-scope="text, record">
        <span class="text-underline cursor"  @click="$util.checkUrl(authority.viewTaskDetail, `/task/taskDetail?id=${record.id}`)">{{ text }}</span>
      </template>
      <template slot="applyContent" slot-scope="text, record">
        <span v-html="text"></span>
      </template>
      <template slot="approveStatus" slot-scope="text, record">
        <span class="text-underline cursor" @click="openApprove(record)" v-if="text === 0">待审批</span>
        <span class="green" v-if="text === 1">同意</span>
        <span class="red" v-if="text === 2">拒绝</span>
      </template>
    </a-table>
    <a-drawer
        title="新增项目"
        placement="right"
        :visible="approveShow"
        @close="closeApprove"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            class="drawer-form"
        >
          <a-form-model-item label="任务名称">
            <div>{{ form.taskName }}</div>
          </a-form-model-item>
          <a-row>
            <a-form-model-item  label="审批点">
              <span v-if="form.expenditureType === 0">无</span>
              <span v-if="form.expenditureType === 1">任务申请进行</span>
              <span v-if="form.expenditureType === 2">任务申请完成</span>
              <span v-if="form.expenditureType === 3">任务申请取消</span>
              <span v-if="form.expenditureType === 4">任务申请搁置</span>
            </a-form-model-item>
            <a-form-model-item label="申请时间">
              <div>{{ form.createTime }}</div>
            </a-form-model-item>
            <a-form-model-item label="申请人">
              <div>{{ form.sponsor }}</div>
            </a-form-model-item>
            <a-form-model-item label="申请审批内容" prop="longitude">
              <div v-html="form.applyContent"></div>
            </a-form-model-item>
          </a-row>
          <a-form-model-item label="批示">
            <a-textarea placeholder="请输入批示" v-model="form.comments" :auto-size="{ minRows: 5, maxRows: 10 }"/>
          </a-form-model-item>
        </a-form-model>
        <a-row class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit(1)">同意申请</a-button>
          <a-button type="default" icon="close" class="margin-l20" @click="onSubmit(2)">拒绝申请</a-button>
        </a-row>
      </div>

    </a-drawer>
  </a-card>
</template>

<script>
import { createApprove, getOtherApprove } from '../../api/center/center'
import { mapState } from 'vuex'

export default {
  name: 'otherProblem',
  data () {
    return {
      columns: [
        {
          title: '申请时间',
          dataIndex: 'createTime'
        },
        {
          title: '关联任务',
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' }
        },
        {
          title: '申请审批内容',
          dataIndex: 'applyContent',
          scopedSlots: { customRender: 'applyContent' }
        },
        {
          title: '审批进度',
          dataIndex: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' }
        }
      ],
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 5
      },
      pagination: {
        total: 0,
        pageSize: 5
      },
      tableLoading: true,
      approveShow: false,
      form: {}
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      getOtherApprove(this.filterForm).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openApprove (item) {
      this.form = { ...item }
      this.approveShow = true
    },
    closeApprove () {
      this.form = {}
      this.approveShow = false
    },
    onSubmit (type) {
      this.form.taskApprovalId = this.form.id
      this.form.approveStatus = type
      createApprove(this.form).then(() => {
        this.$message.success('审批已提交！')
        this.form = {
          pageNum: 1,
              pageSize: 5
        }
        this.getList()
        this.closeApprove()
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
