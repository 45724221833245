<template>
  <a-card title="我的任务" :bodyStyle="{height: '410px'}">
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="taskName" slot-scope="text, record">
        <span class="text-underline cursor" @click="$util.checkUrl(authority.viewTaskDetail, `/task/taskDetail?id=${record.id}`)">{{ text }}</span>
      </template>
      <template slot="projectName" slot-scope="text, record">
        <span class="text-underline cursor" @click="$util.checkUrl(authority.viewProjectDetail, `/project/projectDetail?id=${record.projectId}&title=${record.projectName}`)">{{ text }}</span>
      </template>
      <template slot="taskStatus" slot-scope="text">
        <span v-if="text === 0">未开始</span>
        <span v-if="text === 1">审批中</span>
        <span v-if="text === 2">进行中</span>
        <span v-if="text === 3">已完成</span>
        <span v-if="text === 4">已搁置</span>
        <span v-if="text === 5">已取消</span>
      </template>
      <template slot="taskLevel" slot-scope="text">
        <span v-if="text === 1">重要</span>
        <span v-if="text === 2">普通</span>
      </template>
      <template slot="taskProgressValue" slot-scope="text">
        <a-progress :percent="text * 100 || 0" />
      </template>
      <template slot="fileInfos" slot-scope="text">
        <img src="@/assets/image/common/download.png" alt="" @click="openFiles(text)" v-show="text">
      </template>
    </a-table>
  </a-card>
</template>
<script>
import { getCenterTask } from '../../api/center/center'
import { mapState } from 'vuex'

export default {
  name: 'taskProject',
  data () {
    return {
      columns: [
        {
          title: '任务名称',
          dataIndex: 'taskName',
          scopedSlots: { customRender: 'taskName' }
        },
        {
          title: '项目名称',
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'projectName' }
        },
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          scopedSlots: { customRender: 'taskStatus' }
        },
        {
          title: '任务等级',
          dataIndex: 'taskLevel',
          scopedSlots: { customRender: 'taskLevel' }
        },
        {
          title: '我的任务职位',
          dataIndex: 'myTaskPostionName'
        },
        {
          title: '任务负责人',
          dataIndex: 'taskLeader'
        },
        {
          title: '项目进度',
          dataIndex: 'taskProgressValue',
          scopedSlots: { customRender: 'taskProgressValue' }
        },
        {
          title: '计划开始日期',
          dataIndex: 'planStartDate'
        },
        {
          title: '计划结束日期',
          dataIndex: 'planEndDate'
        },
        {
          title: '实际结束日期',
          dataIndex: 'endDate'
        },
        {
          title: '文件下载',
          dataIndex: 'fileInfos',
          scopedSlots: { customRender: 'fileInfos' }
        }
      ],
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 5
      },
      pagination: {
        total: 0,
        pageSize: 5
      },
      tableLoading: true,
    }
  },
  mounted () {
    this.getCenterProjectList()
  },
  methods: {
    getCenterProjectList() {
      this.tableLoading = true
      getCenterTask(this.filterForm).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openFiles (url) {
      window.open('http://project.xteknology.com/upload/' + url)
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
