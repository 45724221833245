<template>
  <a-row>
    <a-row>
      <Project></Project>
    </a-row>
    <a-row class="margin-t15">
      <Task></Task>
    </a-row>
    <a-row class="margin-t15" :gutter="10">
      <a-col :span="12">
        <MyProblem></MyProblem>
      </a-col>
      <a-col :span="12">
        <OtherProblem></OtherProblem>
      </a-col>
    </a-row>
    <a-row class="margin-t15" :gutter="10">
      <a-col :span="12">
        <MyApprove></MyApprove>
      </a-col>
      <a-col :span="12">
        <OtherApprove></OtherApprove>
      </a-col>
    </a-row>
    <a-row class="margin-t15">
      <AfterSale></AfterSale>
    </a-row>
  </a-row>


</template>

<script>
import { getCenterList } from '@/api/center/center'
import { mapState } from 'vuex'
import Project from './project'
import Task from './task'
import MyProblem from './myProblem'
import OtherProblem from './otherProblem'
import MyApprove from './myApprove'
import OtherApprove from './otherApprove'
import AfterSale from './afterSale'
export default {
  name: 'center',
  components: {
    Project,
    Task,
    MyProblem,
    OtherProblem,
    MyApprove,
    OtherApprove,
    AfterSale
  },
  data () {
    return {
      dataList: {},
    }
  },
  mounted () {

  },
  methods: {

  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
